@import "_var.scss";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/
/*
トップページ
-------------------------------------*/
@keyframes sliderNavi {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

/*
  トップページ
  -------------------------------------*/
#Page.pageIndex {
  #MainImg {
    height: 100% !important;
    #MainImgInner {
      width: 100%;
      max-width: 100%;
      padding: 0;
      h2 {
        width: 372px;
        height: 175px;
        background: url(../img/contents/logo_w.png) no-repeat left top;
        background-size: 100% auto;
        text-indent: -9999px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        &:before { display: none; }
      }
      #Slider {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      .sliderNavi {
        position: absolute;
        display: flex;
        span {
          width: 35px;
          height: 4px;
          background-color: #fff;
          position: relative;
          &:after {
            content: '';
            height: 100%;
            background-color: $color_basic2;
            position: absolute;
            top: 0;
            left: 0;
          }
          &.active:after {
            animation: sliderNavi 12s ease;
          }
          & + span { margin-left: 9px; }
        }
      }
    }
  }
  #Main {
    #ImportBox {
      background-color: #fff;
      dl {
        display: flex;
        dt {
          width: 146px;
          background-color: $color_basic1;
          font-size: 14px;
          font-weight: 500;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    #ContBox01 {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      .innerBasic {
        height: 100%;
        align-items: center;
      }
      .text {
        background-color: #fff;
        padding: 60px 45px;
        box-shadow: 0 3px 6px rgba(0,0,0,0.16);
        h3 {
          font-size: 34px;
          font-weight: 300;
          letter-spacing: 0.06em;
          text-align: center;
          margin-bottom: 28px;
          span {
            display: block;
            font-size: 17px;
            letter-spacing: 0.05em;
            margin-bottom: 16px;
          }
        }
        p {
          font-size: 20px;
          letter-spacing: 0.1em;
          line-height: 30px;
          text-align: center;
          padding: 15px 0;
          margin: 0 auto;
          border-top: 1px solid $color_basic5;
          border-bottom: 1px solid $color_basic5;
        }
      }
    }
    #ContBox02 {
      .postSlider {
        ul {
          margin-bottom: 0;
          li.slick-slide {
            opacity: 0.4;
            &.slick-active { opacity: 1; }
          }
        }
        .slick-list {
          padding-bottom: 34px;
        }
        .slick-arrow {
          width: 70px;
          height: 70px;
          background-repeat: no-repeat;
          background-position: left top;
          background-size: 100% auto;
          box-shadow: 0 3px 6px rgba(0,0,0,0.16);
          border-radius: 50%;
          &:before { display: none; }
        }
        .slick-prev {
          display: none !important;
          background-image: url(../img/contents/arrow_02_l.png);
          z-index: 1;
        }
        .slick-next {
          background-image: url(../img/contents/arrow_02_r.png);
        }
        .slick-dots {
          display: flex;
          text-align: left;
          bottom: 0;
          li {
            width: auto;
            height: auto;
            margin: 0 10px 0 0;
            button {
              width: 35px;
              height: 4px;
              background-color: #E0DCD9;
              padding: 0;
              &:before { display: none; }
            }
            &.slick-active {
              button {
                background-color: $color_basic2;
              }
            }
          }
        }
      }
    }
    #ContBox03 {
      background-color: #fff;
      .btnTypeArrow {
        margin-top: 45px;
        padding-left: 40px;
      }
    }
    #ContBox04 {
      .innerBasic {
        position: relative;
      }
      .btnTypeArrow {
        max-width: 302px;
        margin-top: 36px;
      }
    }
    #ContBox05 {
      background-color: #fff;
      .leadTxt {
        text-align: center;
        letter-spacing: 0.2em;
      }
      .contSubBox {
        .rightBox {
          .btnTypeArrow {
            background-color: transparent;
          }
        }
      }
      .contSubBox02 {
        background-color: $color_basic4;
        .titleTypeBasic {
          &:before { margin-bottom: -3px; }
        }
        .photoTextBox {
          .rightBox {
            h4 {
              text-align: justify;
            }
            h5 {
              font-size: 20px;
              font-weight: 400;
              color: #8E827C;
              letter-spacing: 0.2em;
              line-height: 30px;
              margin-bottom: 29px;
            }
            p {
              line-height: 25px;
              margin-bottom: 35px;
            }
          }
        }
      }
    }
    #ContBox06 {
      background-color: #fff;
      .accessBox {
        .leftBox {
          overflow: hidden;
          iframe {
            width: 100%;
            height: 100%;
          }
        }
        .rightBox {
          h4 {
            font-size: 25px;
            font-weight: 300;
            line-height: 30px;
            margin-bottom: 10px;
          }
          & > p {
            margin-bottom: 11px;
          }
          .snsList {
            text-align: left;
            .fb a { background-image: url(../img/contents/icon_facebook_br.png); }
            .ig a { background-image: url(../img/contents/icon_instragram_br.png); }
          }
          .subBox {
            background-color: #F7F7F7;
            padding: 30px;
            margin-bottom: 40px;
            span {
              display: block;
              background-color: $color_basic1;
              font-size: 16px;
              font-weight: 500;
              color: #fff;
              text-align: center;
              padding: 8px;
            }
            h5 {
              font-size: 25px;
              text-align: center;
              margin: 5px 0 15px;
              a {
                font-size: 35px;
                font-weight: 600;
              }
            }
            p {
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 10px;
            }
            dl {
              display: flex;
              align-items: center;
              & + dl { margin-top: 11px; }
              dt {
                width: 77px;
                background-color: $color_basic1;
                font-size: 12px;
                color: #fff;
                text-align: center;
                padding: 3px;
              }
              dd {
                flex: 1;
                font-size: 14px;
                padding-left: 10px;
              }
            }
          }
          & > a {
            padding-left: 40px;
          }
        }
      }
    }
    #ContBox07 {
      .boxImg {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
      }
      .flexBox {
        .leftBox {
          h3 {
            font-size: 25px;
            font-weight: 300;
            line-height: 30px;
            text-align: center;
            background: url(../img/contents/logo.png) no-repeat center top;
            background-size: 304px auto;
            padding-top: 177px;
          }
        }
        .rightBox {
          a { background-color: transparent; }
        }
      }
    }
  }
  .stickyBox {
    position: sticky;
    left: 0;
    bottom: 0;
    z-index: 1;
    .episode {
      width: 100%;
      background-color: $color_basic3;
      padding: 15px 100px;
      &__post {
        text-align: center;
        line-height: 20px;
        margin: 0;
        a {
          color: #fff;
        }
      }
    }
    .cookie {
      position: absolute;
    }
  }
}
@media print,screen and (min-width: 768px) {
  #Page.pageIndex {
    #MainImg {
      #MainImgInner {
        height: 670px;
        .sliderNavi {
          right: max(calc( (100% - 1200px) / 2 + 22px ), 22px);
          bottom: 51px;
        }
      }
    }
    #Main {
      #ImportBox {
        padding: 31px 0;
        dl {
          width: 705px;
          margin: 0 auto;
          dt {
            margin-right: 37px;
          }
          dd {
            flex: 1;
          }
        }
      }
      #ContBox01 {
        height: 600px;
        background-image: url(../img/contents/top/img_01_pc.jpg);
        .innerBasic {
          padding: 100px 77px;
        }
        .text {
          width: 423px;
        }
      }
      #ContBox02 {
        padding: 100px 0 135px;
        overflow: hidden;
        h3 {
          margin-bottom: 30px;
        }
        .postSlider {
          padding-left: 22px;
          ul li.slick-slide {
            width: 350px;
            & + li { margin-left: 25px; }
          }
          .slick-prev {
            left: 35px;
          }
          .slick-next {
            right: 35px;
            &:hover {
              background-image: url(../img/contents/arrow_02_r_hover.png);
            }
          }
        }
      }
      #ContBox03 {
        .innerBasic {
          padding-top: 55px;
          padding-bottom: 55px;
        }
        .postTypeThumb {
          margin-top: 40px;
          & > li {
            width: calc( (100% - 108px) / 4 );
            margin-right: 36px;
            margin-bottom: 0;
          }
        }
        .btnTypeArrow {
          margin-top: 44px;
          padding-left: 40px;
        }
      }
      #ContBox04 {
        padding: 100px 0;
        .innerBasic {
          padding: 0;
        }
        &:before {
          height: 380px;
        }
      }
      #ContBox05 {
        padding-top: 78px;
        overflow: hidden;
        h3 { margin-bottom: 29px; }
        .leadTxt {
          font-size: 25px;
          line-height: 2;
          margin-bottom: 75px;
        }
        .contSubBox {
          .photoTextBox {
            width: 100%;
            .rightBox {
              a {
                margin: 0 0 10px;
                padding-left: 8.5%;
              }
            }
          }
          .photoTextBox01 {
            .rightBox p { margin-bottom: 40px; }
          }
          .photoTextBox02 {
            .rightBox p { margin-bottom: 16px; }
          }
        }
        .contSubBox01 {
          margin-bottom: 78px;
          .photoTextBox {
            min-height: 570px;
            .rightBox {
              p {
                margin-bottom: 40px;
              }
            }
          }
          .photoTextBox01 {
            .leftBox {
              background-image: url(../img/contents/top/img_02_pc.jpg);
            }
            .rightBox {
              padding-left: 72px;
              p {
                line-height: 35px;
              }
            }
          }
          .photoTextBox02 {
            .leftBox {
              background-image: url(../img/contents/top/img_03_pc.jpg);
            }
            .rightBox {
              padding-right: 72px;
              p {
                line-height: 40px;
              }
            }
          }
        }
        .contSubBox02 {
          .photoTextBox {
            min-height: 445px;
            .leftBox {
              width: calc( (100% - 36%) + 22px);
            }
            .rightBox {
              width: 36%;
              h4 {
                margin-bottom: 30px;
              }
            }
            &:nth-of-type(odd) {
              .rightBox {
                padding-left: 46px;
              }
            }
            &:nth-of-type(even) {
              .rightBox {
                padding-right: 46px;
              }
            }
          }
          .photoTextBox01 {
            .leftBox { background-image: url(../img/contents/top/img_04_pc.jpg); }
          }
          .photoTextBox02 {
            .leftBox { background-image: url(../img/contents/top/img_05_pc.jpg); }
          }
          .photoTextBox03 {
            .leftBox { background-image: url(../img/contents/top/img_06_pc.jpg); }
          }
          .photoTextBox04 {
            .leftBox { background-image: url(../img/contents/top/img_07_pc.jpg); }
          }
        }
      }
      #ContBox06 {
        .innerBasic {
          padding-top: 60px;
          padding-bottom: 60px;
        }
        h3 {
          font-size: 22px;
        }
        .accessBox {
          .leftBox {
            width: 61%;
            margin-right: 55px;
          }
          .rightBox {
            flex: 1;
            .listTypeDot {
              margin: 15px 0 24px;
            }
          }
        }
      }
      #ContBox07 {
        .boxImg {
          height: 432px;
          background-image: url(../img/contents/top/img_08_01.jpg);
        }
        .innerBasic {
          padding-top: 69px;
          padding-bottom: 49px;
        }
        .flexBox {
          justify-content: right;
          .leftBox {
            display: flex;
            padding-top: 32px;
          }
          .rightBox {
            width: 50%;
            margin-left: 16.25%;
            p {
              line-height: 35px;
              margin-bottom: 60px;
            }
            a {
              max-width: 414px;
              width: 100%;
              text-align: center;
              padding: 12px 0;
              margin: 0;
            }
          }
        }
      }
    }
  }
}
@media print,screen and (min-width: 1200px) {
  #Page.pageIndex {
    #Main {
      #ContBox05 .contSubBox02 .photoTextBox {
        .leftBox { width: 900px; }
        .rightBox { width: 398px; }
      }
    }
  }
}
@media print,screen and (max-width: 767px) {
  #Page.pageIndex {
    #MainImg {
      #MainImgInner {
        height: 470px;
        padding: 0;
        h2 {
          width: 215px;
          height: 101px;
        }
        .sliderNavi {
          right: 20px;
          bottom: 20px;
        }
      }
    }
    #Main {
      #ImportBox {
        padding: 31px 0 35px;
        dl {
          flex-direction: column;
          align-items: flex-start;
          dt { padding: 9px 0 10px; }
          dd {
            width: 100%;
            margin-top: 20px;
          }
        }
      }
      .contBox {
        padding: 50px 0;
      }
      #ContBox01 {
        height: 100%;
        background-image: url(../img/contents/top/img_01_sp.jpg);
        background-position: center top;
        padding: 0;
        .innerBasic {
          padding-top: 282px;
          padding-bottom: 38px;
        }
        .text {
          width: 100%;
          max-width: 300px;
          padding: 40px;
          margin: 0 auto;
          h3 {
            font-size: 28px;
            margin-bottom: 16px;
            span {
              font-size: 16px;
              line-height: 20px;
              margin-bottom: 6px;
            }
          }
          p {
            width: 100%;
            font-size: 18px;
            line-height: 25px;
            padding: 10px 0;
          }
        }
      }
      #ContBox02 {
        h3 {
          margin-bottom: 23px;
          &:before { margin-bottom: -6px; }
        }
        .postSlider {
          .slick-list {
            padding-bottom: 30px;
          }
          .slick-arrow {
            display: none !important;
          }
        }
      }
      #ContBox03 {
        h3 {
          margin-bottom: 31px;
        }
        .btnTypeArrow {
          margin-top: 39px;
        }
      }
      #ContBox04 {
        h3 {
          margin-bottom: 40px;
        }
        .btnTypeArrow {
          margin-top: 40px;
        }
      }
      #ContBox05 {
        padding-bottom: 0;
        h3 {
          margin-bottom: 35px;
        }
        .leadTxt {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 35px;
          padding: 0 20px;
        }
        .contSubBox {
          .photoTextBox {
            margin-bottom: 50px;
            .rightBox {
              padding-top: 30px;
            }
          }
          .btnTypeArrow {
            padding-left: 40px;
            margin: 0;
          }
        }
        .contSubBox01 {
          .photoTextBox {
            .leftBox {
              padding-top: calc(102.5641025641026% + 40px);
            }
            .rightBox {
              h4 {
                margin-bottom: 10px;
              }
              p {
                line-height: 30px;
                margin-bottom: 25px;
              }
            }
          }
          .photoTextBox01 {
            .leftBox { background-image: url(../img/contents/top/img_02_sp.jpg); }
          }
          .photoTextBox02 {
            .leftBox { background-image: url(../img/contents/top/img_03_sp.jpg); }
          }
        }
        .contSubBox02 {
          padding-bottom: 50px;
          .photoTextBox {
            &:last-of-type {
              margin-bottom: 0;
            }
            .leftBox {
              padding-top: calc(51.28205128205128% + 20px);
            }
            .rightBox {
              h4 {
                margin-bottom: 15px;
                &:before { margin-bottom: -5px; }
              }
              p { margin-bottom: 24px; }
            }
          }
          .photoTextBox01 {
            .leftBox { background-image: url(../img/contents/top/img_04_sp.jpg); }
          }
          .photoTextBox02 {
            .leftBox { background-image: url(../img/contents/top/img_05_sp.jpg); }
          }
          .photoTextBox03 {
            .leftBox { background-image: url(../img/contents/top/img_06_sp.jpg); }
          }
          .photoTextBox04 {
            .leftBox { background-image: url(../img/contents/top/img_07_sp.jpg); }
          }
        }
      }
      #ContBox06 {
        .innerBasic {
          padding: 0;
        }
        h3 {
          margin-bottom: 30px;
        }
        .accessBox {
          .leftBox {
            width: 100%;
            padding-top: 88.57142857142857%;
            position: relative;
            iframe {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
          .rightBox {
            width: 100%;
            padding: 10px 20px 0;
            .snsList {
              margin: 15px 0 14px;
            }
            .subBox {
              width: calc(100% + 40px);
              margin: 20px 0 25px -20px;
              padding: 30px;
              h5 {
                letter-spacing: 0;
                margin: 10px 0;
              }
              .text {
                max-width: 305px;
                margin: 0 auto;
              }
            }
          }
        }
      }
      #ContBox07 {
        padding: 0 0 60px;
        .boxImg {
          height: 400px;
          background-image: url(../img/contents/top/img_08_02.jpg);
        }
        .flexBox {
          margin-top: 30px;
          .leftBox {
            width: 100%;
            margin-bottom: 39px;
            h3 {
              background-size: 244px auto;
              padding-top: 148px;
            }
          }
          .rightBox {
            p {
              line-height: 35px;
              margin-bottom: 60px;
            }
          }
        }
      }
    }
    .stickyBox {
      .episode {
        padding: 25px 20px;
        &__post {
          color: #fff;
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -webkit-text-overflow: ellipsis;
        }
      }
    }
  }
}

/*
お知らせ 共通
-------------------------------------*/
@media print,screen and (min-width: 768px) {
  #PageInfo {
    #MainImg{
      background-image: url(../img/contents/information/mainimg_01.jpg);
    }
  }
}

@media print,screen and (max-width: 767px) {
  #PageInfo {
    #MainImg{
      background-image: url(../img/contents/information/mainimg_sp_01.jpg);
    }
  }
}

/*
お知らせ
-------------------------------------*/
#PageInfo.pageIndex {
  #Main {
    padding: 74px 0 83px;
    #ContBox01 {
      .postTypeThumb {
        margin-bottom: 46px;
        & > li {
          a { background-color: transparent; }
        }
      }
    }
  }
}

@media print,screen and (max-width: 767px) {
  #PageInfo.pageIndex {
    #Main {
      padding: 40px 0 74px;
      #ContBox01 {
        .postTypeThumb {
          margin-bottom: 25px;
          & > li {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

/*
詳細（お知らせ）
-------------------------------------*/
#PageInfo.pageEntry {
  #Main {
    #ContBox01 {
      .contSubBox {
        background-color: #fff;
        box-shadow: 0 3px 10px rgba(0,0,0,0.16);
        .date {
          display: block;
          font-size: 16px;
          letter-spacing: 0.1em;
          text-align: right;
          margin-bottom: 24px;
        }
        .title {
          font-size: 25px;
          letter-spacing: 0.1em;
          line-height: 36px;
          text-align: justify;
          margin-bottom: 40px;
        }
      }
      .pageNav {
        margin-top: 87px;
      }
    }
  }
}

@media print,screen and (min-width: 768px) {
  #PageInfo.pageEntry {
    #Main {
      padding: 114px 0 246px;
      #ContBox01 {
        .contSubBox {
          max-width: 968px;
          padding: 60px;
          margin: 0 auto;
        }
        .pageNav {
          ul {
            width: 622px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}
@media print,screen and (max-width: 767px) {
  #PageInfo.pageEntry {
    #Main {
      padding: 49px 0 98px;
      #ContBox01 {
        .contSubBox {
          padding: 18px 25px 33px;
          .date {
            margin-bottom: 6px;
          }
          .title {
            font-size: 20px;
            line-height: 25px;
            padding-right: 90px;
            margin-bottom: 32px;
          }
        }
        .pageNav {
          margin-top: 36px;
          ul {
            padding-top: 51px;
            li {
              &.prev, &.next {
                top: 0;
                transform: translateY(0);
              }
            }
          }
        }
      }
    }
  }
}

/*
ウエディングレポート 共通
-------------------------------------*/
@media print,screen and (min-width: 768px) {
  #PageReport {
    #MainImg{
      background-image: url(../img/contents/report/mainimg_01.jpg);
    }
  }
}

@media print,screen and (max-width: 767px) {
  #PageReport {
    #MainImg{
      background-image: url(../img/contents/report/mainimg_sp_01.jpg);
    }
  }
}

/*
ウエディングレポート
-------------------------------------*/
#PageReport.pageIndex {}

@media print,screen and (min-width: 768px) {
  #PageReport.pageIndex {
    #Main {
      padding: 75px 0 110px;
      #ContBox01 {
        .innerBasic { padding: 0; }
        .postTypeReportThumb {
          margin-bottom: 27px;
          & > li {
            margin-bottom: 34px;
          }
        }
      }
    }
  }
}

@media print,screen and (max-width: 767px) {
  #PageReport.pageIndex {
    #Main {
      padding: 41px 0 75px;
      #ContBox01 {
        .postTypeReportThumb {
          margin-bottom: 57px;
          li {
            margin-top: 15px;
          }
        }
      }
    }
  }
}

/*
詳細（ウエディングレポート）
-------------------------------------*/
#PageReport.pageEntry {
  #Main {
    #ContBox01 {
      position: relative;
      .background {
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        -ms-filter: blur(22px);
        filter: blur(22px);
      }
      .contSubBox01 {
        .thumbnail {
          background-repeat: no-repeat;
          background-position: center top;
          background-size: cover;
          margin: 0 auto;
        }
        .head {
          background-color: #fff;
          box-shadow: 6px 6px 10px rgba(0,0,0,0.16);
          margin: 0 auto;
          .catchcopy {
            font-size: 25px;
            color: $color_basic2;
            letter-spacing: 0.1em;
            text-align: center;
            margin-bottom: 28px;
            span {
              font-size: 35px;
            }
          }
          .title {
            font-size: 25px;
            letter-spacing: 0.1em;
            line-height: 40px;
            text-align: center;
            margin-bottom: 26px;
          }
          dl {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 35px;
            dt {
              width: 25%;
              max-width: 70px;
              background-color: $color_basic1;
              color: #fff;
              line-height: 25px;
              text-align: center;
              margin-right: 9px;
            }
            dd {
              width: 25%;
              margin-right: 30px;
              &:last-of-type { margin-right: 0; }
            }
          }
          .planner {
            font-size: 12px;
            line-height: 15px;
            text-align: center;
            margin-bottom: 0;
          }
        }
      }
      .contSubBox02 {
        .photoTextBox {
          .leftBox {
            background-position: center center;
          }
          .rightBox {
            position: relative;
            .num {
              font-size: 200px;
              font-weight: 300;
              color: #F0E9E1;
              letter-spacing: 0;
              position: absolute;
              z-index: -1;
            }
            h4 {
              font-size: 25px;
              letter-spacing: 0.1em;
              line-height: 40px;
              text-align: justify;
              margin-bottom: 38px;
            }
            p {
              line-height: 25px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
@media print,screen and (min-width: 768px) {
  #PageReport.pageEntry {
    #Main {
      padding: 114px 0 195px;
      #ContBox01 {
        overflow: hidden;
        .background {
          height: 546px;
        }
        .contSubBox01 {
          margin-bottom: 98px;
          .thumbnail {
            width: 623px;
            height: 546px;
          }
          .head {
            width: 600px;
            padding: 27px;
            margin-top: -111px;
          }
        }
        .contSubBox02 {
          .photoTextBox {
            display: flex;
            & + .photoTextBox {
              margin-top: 136px;
            }
            .leftBox {
              height: 0;
              padding-top: 32.26643598615917%;
            }
            .rightBox {
              padding-top: 110px;
              padding-bottom: 0;
              .num { top: -86px; }
            }
            &:nth-of-type(odd) {
              .rightBox {
                padding-left: 50px;
                .num {
                  right: 0;
                }
              }
            }
            &:nth-of-type(even) {
              flex-direction: row-reverse;
              .rightBox {
                padding-right: 50px;
                .num {
                  left: 0;
                }
              }
            }
          }
        }
        .pageNav {
          margin-top: 157px;
        }
      }
    }
  }
}
@media print,screen and (min-width: 1200px) {
  #PageReport.pageEntry {
    #Main {
      #ContBox01 {
        .contSubBox02 {
          .photoTextBox .leftBox {
            padding-top: 373px;
          }
        }
      }
    }
  }
}
@media print,screen and (max-width: 767px) {
  #PageReport.pageEntry {
    #Main {
      #ContBox01 {
        padding: 22px 0 65px;
        .background {
          display: none;
        }
        .contSubBox01 {
          margin-bottom: 45px;
          .thumbnail {
            width: calc(100% + 40px);
            height: 352px;
            margin-left: -20px;
          }
          .head {
            padding: 30px 25px 26px;
            margin-top: -32px;
            .catchcopy {
              font-size: 20px;
              margin-bottom: 14px;
              span {
                font-size: 30px;
              }
            }
            .title {
              margin: 0 45px;
              margin-bottom: 15px;
            }
            dl {
              margin-bottom: 24px;
            }
          }
        }
        .contSubBox {
          .photoTextBox {
            & + .photoTextBox {
              margin-top: 48px;
            }
            .leftBox {
              width: 100%;
              padding-top: 57.14285714285714%;
              margin-left: 0;
            }
            .rightBox {
              margin-top: 80px;
              .num {
                font-size: 100px;
                top: -95px;
                left: 50%;
                transform: translateX(-50%);
              }
              h4 {
                font-size: 20px;
                line-height: 25px;
                margin-bottom: 20px;
              }
              p {
                line-height: 28px;
              }
            }
          }
        }
        .pageNav {
          margin-top: 47px;
        }
      }
    }
  }
}

/*
オリジナルウエディング
-------------------------------------*/
#PageOrg-Wedd.pageIndex {
  #Main {
    .contBox {
      .titleTypeBasic {
        margin-bottom: 28px;
        &:before { margin-bottom: -6px; }
      }
      .leadText {
        h4 {
          font-size: 25px;
          line-height: 40px;
          letter-spacing: 0.2em;
          text-align: center;
          margin-bottom: 50px;
        }
        p {
          font-size: 20px;
          line-height: 35px;
          text-align: center;
          margin-bottom: 58px;
        }
      }
      .leadImage {
        img {
          width: 100%;
          height: auto;
        }
      }
      .photoSlide {
        width: 100%;
        height: 379px;
        position: relative;
        * { height: 100%; }
        .slick {
          width: calc(100% + 22px);
          .slider {
            margin-right: 33px;
            overflow: hidden;
            img {
              display: block;
              width: auto;
              height: 100%;
              object-fit: cover;
            }
          }
        }
        .slick-arrow {
          width: 70px;
          height: 70px;
          background-repeat: no-repeat;
          background-position: left top;
          background-size: cover;
          border-radius: 50%;
          box-shadow: 0 3px 6px rgba(0,0,0,0.16);
          &:before { display: none; }
        }
        .slick-prev {
          background-image: url(../img/contents/arrow_02_l.png);
          z-index: 1;
        }
        .slick-next {
          background-image: url(../img/contents/arrow_02_r.png);
        }
        .slick-disabled {
          opacity: 1;
          visibility: hidden;
        }
      }
    }
    #ContBox01 {
      .leadText {
        margin-bottom: 41px;
      }
      .leadImage {
        margin-bottom: -100px;
      }
      .contSubBox {
        .photoTextBox {
          .rightBox {
            display: flex;
            flex-direction: column;
            justify-content: center;
            h5 {
              margin-bottom: 30px;
            }
            p {
              line-height: 25px;
              margin-bottom: 0;
            }
          }
        }
      }
    }
    #ContBox02 {
      .contSubBox {
        .flexBox {
          .text {
            h5 {
              font-size: 30px;
              font-weight: 300;
              color: $color_basic2;
              line-height: 36px;
              text-transform: uppercase;
              margin-bottom: 25px;
            }
            p {
              font-size: 20px;
              line-height: 35px;
              margin-bottom: 0;
            }
          }
          .photo {
            img {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    #ContBox03 {
      .photoSlideBox {
        p {
          font-size: 20px;
          line-height: 35px;
          text-align: center;
          margin-bottom: 20px;
        }
      }
      .photoTextBox {
        .rightBox {
          h4 {
            margin-bottom: 25px;
          }
          p {
            font-size: 20px;
            line-height: 35px;
            margin-bottom: 0;
          }
        }
      }
      .photoSlide01 {
        .slickSp {
          .slider { margin-right: 33px; }
        }
      }
      .photoTextBox01 {
        .leftBox {
          background-image: url(../img/contents/original-wedding/img_04_05.jpg);
        }
        .rightBox {
          h4 {
            font-size: 30px;
            font-weight: 300;
            color: $color_basic2;
            text-transform: uppercase;
            line-height: 36px;
          }
        }
      }
      .photoTextBox02 {
        .leftBox {
          background-image: url(../img/contents/original-wedding/img_04_06.jpg);
        }
      }
    }
    #ContBox04 {
      .photoTextBox {
        .leftBox {
          img { height: auto; }
        }
        .rightBox {
          h6 {
            line-height: 35px;
            text-align: justify;
          }
          p {
            line-height: 25px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media print,screen and (min-width: 768px) {
  #PageOrg-Wedd.pageIndex {
    #MainImg {
      background-image: url(../img/contents/original-wedding/mainimg_01.jpg);
    }
    #Main {
      .contBox {
        padding: 50px 0;
        overflow: hidden;
        .photoSlide {
          .slick-prev {
            left: 10px;
            &:hover {
              background-image: url(../img/contents/arrow_02_l_hover.png);
            }
          }
          .slick-next {
            right: 32px;
            &:hover {
              background-image: url(../img/contents/arrow_02_r_hover.png);
            }
          }
        }
      }
      #ContBox01 {
        padding-top: 0;
        overflow: hidden;
        .leadText {
          h4 { margin-bottom: 41px; }
        }
        .contSubBox01 {
          margin-bottom: 95px;
          .photoTextBox {
            min-height: 570px;
            .leftBox {
              background-image: url(../img/contents/original-wedding/img_01_02.jpg);
            }
            .rightBox {
              padding-left: 8%;
            }
          }
          .photoArea {
            position: relative;
            text-align: right;
            margin-top: -19px;
            img {
              height: auto;
            }
            .photo01 {
              width: 57.09342560553633%;
              margin-bottom: 53px;
            }
            .photo02 {
              width: 36.93771626297578%;
              position: absolute;
              top: 77px;
              left: -37px;
            }
            .photo03 {
              width: 74.74048442906574%;
              margin-right: -12.28373702422145%;
            }
          }
        }
        .contSubBox02 {
          .photoTextBox {
            min-height: 507px;
            margin-bottom: -9%;
            .leftBox {
              width: calc(50% + 87px);
              background-image: url(../img/contents/original-wedding/img_02_01.jpg);
            }
            .rightBox {
              width: calc(50% - 65px);
              padding-top: 60px;
              padding-left: 6.05%;
              justify-content: flex-start;
            }
          }
        }
      }
      #ContBox02 {
        .contSubBox {
          .flexBox {
            display: flex;
            .text {
              width: 50%;
              padding-right: 22px;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            .photo {
              width: 50%;
              position: relative;
            }
          }
          .flexBox02 {
            flex-direction: row-reverse;
            .text {
              width: calc(50% - 135px);
              align-items: flex-end;
              padding-left: 22px;
              padding-right: 0;
            }
            .photo {
              width: calc(50% + 157px);
              margin-left: -22px;
            }
          }
          .photoArea {
            display: flex;
            justify-content: space-between;
            margin-top: 50px;
            img {
              width: calc( (100% - 33px) / 2 );
              height: auto;
            }
          }
        }
        .contSubBox01 {
          .flexBox01 {
            .text {
              padding: 5% 22px 5% 9%;
            }
            .photo {
              img {
                width: 70%;
                position: absolute;
                top: -35.8%;
                right: -10px;
              }
            }
          }
          .flexBox02 {
            .text { padding-top: 20%; }
          }
        }
        .contSubBox02 {
          margin-top: 50px;
          .flexBox01 {
            .text {
              width: calc(50% - 135px);
              padding-right: 36px;
            }
            .photo {
              width: calc(50% + 157px);
              margin-right: -22px;
            }
          }
          .flexBox02 {
            margin-top: 50px;
            .text { padding-right: 2.6%; }
          }
        }
      }
      #ContBox03 {
        .innerBasic {
          & > div {
            margin-bottom: 50px;
            &:last-of-type { margin-bottom: 0; }
          }
        }
        .leadText {
          p {
            font-size: 16px;
            line-height: 25px;
          }
        }
        .leadImage {
          margin-bottom: -110px;
        }
        .photoSlide01 {
          height: auto;
          padding: 0 20px;
          .slickSp {
            display: flex;
            justify-content: center;
            .slider {
              width: calc( (100% - 66px ) / 3 );
              &:last-of-type { margin-right: 0; }
            }
          }
        }
        .photoTextBox01 {
          min-height: 460px;
          .rightBox {
            width: calc(50% - 135px);
            padding-right: 48px;
          }
          .leftBox {
            width: calc(50% + 157px);
          }
        }
        .photoTextBox02 {
          min-height: 670px;
          .leftBox {
            width: calc(50% + 82px);
          }
          .rightBox {
            width: calc(50% - 60px);
            padding-left: 6%;
            h4 {
              font-size: 30px;
              line-height: 40px;
            }
          }
        }
      }
      #ContBox04 {
        .photoTextBox {
          flex-direction:row-reverse;
          margin-top: 70px;
          margin-bottom: 100px;
          .leftBox {
            position: relative;
            img {
              width: 79.5%;
              max-width: 477px;
              position: absolute;
              right: -12px;
              top: -50%;
            }
          }
          .rightBox {
            h5 {
              font-size: 40px;
              position: relative;
              margin-bottom: 20px;
              &:after {
                content: '';
                width: 52px;
                border-top: 1px solid #707070;
                position: absolute;
                top: 55%;
                left: 78%;
                transform: translateY(-50%);
              }
            }
            h6 {
              font-size: 20px;
              margin-bottom: 30px;
            }
          }
        }
        .photoSlide {
          height: 482px;
        }
      }
      #ContBox05 {
        h3 {
          &:before { margin-bottom: -5px; }
        }
        .photoSlide {
          height: 482px;
          margin-top: 35px;
        }
      }
      #ContBox06 {
        h3 {
          &:before { margin-bottom: -5px; }
        }
        .leadText {
          h4 {
            margin-bottom: 25px;
          }
          p {
            font-size: 18px;
          }
        }
        .photoSlide {
          margin-top: 7px;
          height: 482px;
        }
      }
    }
  }
}
@media print,screen and (min-width: 1200px) {
  #PageOrg-Wedd.pageIndex {
    #Main {
      .contBox {
        .photoSlide {
          .slick { width: calc(100% + 142px); }
          .slick-next { right: 142px; }
          .slick-prev { left: -22px; }
        }
      }
      #ContBox01 {
        .contSubBox02 .photoTextBox {
          .leftBox {
            width: calc(50% + 207px);
          }
        }
      }
      #ContBox02 {
        .contSubBox {
          .flexBox02 {
            .photo {
              width: 855px;
              margin-left: -142px;
            }
          }
        }
        .contSubBox02 {
          .flexBox01 {
            .photo {
              width: 855px;
              margin-right: -142px;
            }
          }
        }
      }
      #ContBox03 {
        .photoTextBox01 {
          .leftBox { width: 855px; }
        }
        .photoTextBox02 {
          .leftBox { width: 780px; }
          .rightBox { padding-left: 75px; }
        }
      }
      #ContBox04 {
        .photoTextBox {
          .leftBox {
            img { top: -276px; }
          }
        }
      }
    }
  }
}
@media print,screen and (max-width: 767px) {
  #PageOrg-Wedd.pageIndex {
    #MainImg {
      background-image: url(../img/contents/original-wedding/mainimg_sp_01.jpg);
      #MainImgInner {
        h2 {
          letter-spacing: 0;
          &:before {
            font-size: 30px;
          }
        }
      }
    }
    #Main {
      .contBox {
        padding: 25px 0;
        .titleTypeBasic {
          &:before { margin-bottom: -6px; }
        }
        .leadText {
          padding: 0 20px;
          h4 {
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 34px;
          }
          p {
            font-size: 18px;
            text-align: justify;
            margin-bottom: 40px;
          }
        }
        .photoSlide {
          .slick {
            width: calc(100% + 20px);
            .slider {
              margin-right: 18px;
            }
          }
          .slick-arrow {
            width: 58px;
            height: 58px;
          }
          .slick-prev {
            background-image: url(../img/contents/arrow_02_l_hover.png);
            left: -13px;
          }
          .slick-next {
            background-image: url(../img/contents/arrow_02_r_hover.png);
            right: 9px;
          }
        }
        .photoArea {
          img {
            margin-bottom: 20px;
            &:last-of-type { margin-bottom: 0; }
          }
        }
      }
      #ContBox01 {
        padding-top: 0;
        .contSubBox01 {
          margin-bottom: 50px;
          .photoTextBox {
            margin-bottom: 40px;
            .leftBox {
              width: 100%;
              background-image: url(../img/contents/original-wedding/img_01_02_sp.jpg);
              padding-top: 108.5714285714286%;
              margin: 0 0 50px;
            }
          }
          .photoArea {
            display: flex;
            flex-direction: column;
            img {
              &:nth-of-type(1) { order: 2; }
              &:nth-of-type(2) { order: 1; }
              &:nth-of-type(3) { order: 3; }
            }
          }
        }
        .contSubBox02 {
          .photoTextBox {
            flex-direction: column-reverse;
            margin-bottom: -48px;
            .leftBox {
              background-image: url(../img/contents/original-wedding/img_02_01_sp.jpg);
              padding-top: calc(91.25% + 40px);
              margin-top: 35px;
            }
          }
          .photoSlide {
            height: 210px;
          }
        }
      }
      #ContBox02 {
        .leadImage {
          margin-bottom: 40px;
        }
        .contSubBox {
          .flexBox {
            .text {
              margin-bottom: 20px;
              p { font-size: 18px; }
            }
            .photo {
              width: calc(100% + 40px);
              margin-left: -20px;
            }
          }
          .flexBox01 {
            margin-bottom: 40px;
          }
          .flexBox02 {
            margin-bottom: 20px;
          }
        }
        .contSubBox02 {
          margin-top: 40px;
        }
      }
      #ContBox03 {
        .leadText {
          p {
            font-size: 16px;
            line-height: 25px;
          }
        }
        .leadImage {
          margin-bottom: -50px;
        }
        .photoSlide01 {
          height: 278px;
          margin-bottom: 40px;
          .slickSp {
            width: calc(100% + 20px);
            .slider img {
              width: auto;
            }
          }
        }
        .photoSlide02,
        .photoSlide03 {
          height: 225px;
        }
        .photoTextBox {
          flex-direction: column-reverse;
          .rightBox {
            margin-bottom: 20px;
            p {
              font-size: 18px;
            }
          }
        }
        .photoTextBox01 {
          margin-bottom: 48px;
          .leftBox {
            padding-top: calc(55.12857142857143% + 40px);
          }
        }
        .photoSlideBox {
          margin-bottom: 20px;
          p {
            font-size: 18px;
            text-align: justify;
            margin-bottom: 20px;
          }
        }
        .photoTextBox02 {
          margin-top: 56px;
          .rightBox {
            h4 {
              font-size: 20px;
              line-height: 30px;
              text-align: center;
              margin-bottom: 35px;
            }
          }
          .leftBox {
            padding-top: calc(88.5% + 40px);
          }
        }
      }
      #ContBox04 {
        .leadImage {
          margin-bottom: -27px;
        }
        .photoTextBox {
          margin-bottom: 20px;
          .leftBox {
            width: 100%;
            margin-left: 0;
            margin-bottom: 20px;
          }
          .rightBox {
            h5 {
              font-size: 25px;
              line-height: 36px;
              text-align: center;
              margin-bottom: 35px;
            }
            h6 {
              font-size: 18px;
              margin-bottom: 30px;
            }
          }
        }
        .photoSlide {
          height: 390px;
        }
      }
      #ContBox05 {
        .leadText {
          h4 { margin-bottom: 20px; }
        }
        .leadImage {
          margin-bottom: -11px;
        }
        .photoSlide {
          height: 390px;
        }
      }
      #ContBox06 {
        padding-bottom: 100px;
        .leadImage {
          margin-bottom: -98px;
        }
        .photoSlide {
          height: 246px;
        }
      }
    }
  }
}

/*
ウエディングプラン 共通
-------------------------------------*/
@media print,screen and (min-width: 768px) {
  #PagePlan {
    #MainImg{
      background-image: url(../img/contents/plan/mainimg_01.jpg);
    }
  }
}

@media print,screen and (max-width: 767px) {
  #PagePlan {
    #MainImg{
      background-image: url(../img/contents/plan/mainimg_sp_01.jpg);
    }
  }
}

/*
ウエディングプラン
-------------------------------------*/
#PagePlan.pageIndex {
  #Main {
    #ContBox01 {
      h3 {
        margin-bottom: 58px;
      }
      .planBox {
        display: flex;
        flex-wrap: wrap;
        .plan {
          text-decoration: none;
          .thumb {
            width: 100%;
            overflow: hidden;
            .thumbInner {
              width: 100%;
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;
            }
          }
          .content {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            .label {
              display: block;
              font-family: 'Montserrat', sans-serif;
              font-weight: 300;
              color: $color_basic2;
              letter-spacing: 0;
            }
            h4 {
              letter-spacing: 0;
              text-align: justify;
              border-bottom: 1px solid #E2DEDA;
            }
            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }
    #ContBox02 {
      background-color: #fff;
      h3 {
        span {
          display: block;
        }
      }
      .originalBox {
        display: flex;
        flex-wrap: wrap;
        .item {
          dt {
            width: 100%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
          dd {
            &.title {
              font-family: "Noto Serif JP", "游明朝体", "YuMincho", "Yu Mincho", "ヒラギノ明朝 Pro W3","Hiragino Mincho Pro", "ＭＳ 明朝", serif;
              letter-spacing: 0;
              border-bottom: 1px solid #E2DEDA;
            }
            &.content {
              text-align: justify;
            }
          }
        }
        .item01 dt {
          background-image: url(../img/contents/plan/img_01.jpg);
        }
        .item02 dt {
          background-image: url(../img/contents/plan/img_02.jpg);
        }
        .item03 dt {
          background-image: url(../img/contents/plan/img_03.jpg);
        }
        .item04 dt {
          background-image: url(../img/contents/plan/img_04.jpg);
        }
        .item05 dt {
          background-image: url(../img/contents/plan/img_05.jpg);
        }
        .item06 dt {
          background-image: url(../img/contents/plan/img_06.jpg);
        }
      }
    }
    #ContBox03 {
      h3 {
        margin-bottom: 47px;
        &:before { margin-bottom: -6px; }
      }
      .subBox {
        border-top: 1px solid $color_basic5;
        &:last-of-type {
          border-bottom: 1px solid $color_basic5;
        }
        h4 {
          line-height: 25px;
          background: url(../img/contents/plan/icon_open.png) no-repeat right 13px center;
          background-size: 20px auto;
          position: relative;
          &:before {
            content: 'Q';
            font-family: 'Montserrat', sans-serif;
            font-weight: 300;
            font-size: 30px;
            color: $color_basic5;
            position: absolute;
            top: 0;
            left: 0;
          }
          &.active {
            background-image: url(../img/contents/plan/icon_close.png);
          }
        }
        .showBox {
          position: relative;
          &:before {
            content: 'A';
            font-family: 'Montserrat', sans-serif;
            font-weight: 300;
            font-size: 30px;
            color: $color_basic2;
            line-height: 1;
            position: absolute;
            top: 0;
            left: 0;
          }
          p {
            line-height: 25px;
            margin-bottom: 0;
          }
        }
      }
    }
  }
}

@media print,screen and (min-width: 768px) {
  #PagePlan.pageIndex {
    #Main {
      #ContBox01 {
        padding-bottom: 90px;
        .planBox {
          .plan {
            width: calc((100% - 116px) / 2 );
            margin-bottom: 73px;
            .thumb {
              box-shadow: 10px 10px 30px rgba(255,181,181,0.2);
              .thumbInner {
                padding-top: 57.69230769230769%;
                transform: scale(1);
                transition: 0.5s ease;
              }
            }
            .content {
              margin-top: 29px;
              .label {
                font-size: 20px;
                margin-bottom: 18px;
              }
              h4 {
                font-size: 20px;
                line-height: 30px;
                padding-bottom: 20px;
                margin-bottom: 29px;
              }
              p {
                line-height: 25px;
              }
            }
            &:nth-of-type(odd) {
              margin-right: 116px;
            }
            &:hover {
              .thumb .thumbInner { transform: scale(1.12); }
            }
          }
        }
      }
      #ContBox02 {
        padding: 98px 0 28px;
        h3 {
          margin-bottom: 64px;
          span {
            font-size: 25px;
            letter-spacing: 0.06em;
            line-height: 40px;
            margin-top: -3px;
          }
        }
        .originalBox {
          .item {
            width: calc( (100% - 76px) / 3 );
            margin-right: 38px;
            margin-bottom: 73px;
            &:nth-of-type(3n) {
              margin-right: 0;
            }
            dt {
              height: 300px;
            }
            dd {
              &.title {
                font-size: 20px;
                padding-bottom: 18px;
                margin: 24px 0 29px;
              }
              &.content {
                line-height: 25px;
              }
            }
          }
        }
      }
      #ContBox03 {
        padding: 73px 0 283px;
        .subBox {
          max-width: 1000px;
          margin: 0 auto;
          h4 {
            font-size: 18px;
            padding: 21px 44px;
            opacity: 1;
            transition: opacity 0.5s ease;
            &:before { top: 20px; }
            &:hover { opacity: 0.5; }
          }
          .showBox {
            padding-left: 44px;
            padding-bottom: 32px;
          }
        }
      }
    }
  }
}

@media print,screen and (max-width: 767px) {
  #PagePlan.pageIndex {
    #Main {
      #ContBox01 {
        padding-bottom: 50px;
        h3 {
          margin-bottom: 25px;
        }
        .planBox {
          margin-bottom: 27px;
          .plan {
            width: calc( (100% - 20px) / 2 );
            margin-bottom: 12px;
            &:nth-of-type(odd) {
              margin-right: 20px;
            }
            .thumb {
              .thumbInner { padding-top: 69.6969696969697%; }
            }
            .content {
              margin-top: 12px;
              .label {
                font-size: 14px;
                margin-bottom: 8px;
              }
              .title {
                font-size: 16px;
                line-height: 20px;
                padding-bottom: 17px;
                margin-bottom: 9px;
              }
              p {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
      #ContBox02 {
        padding: 30px 0 10px;
        h3 {
          font-size: 30px;
          line-height: 40px;
          margin-bottom: 40px;
          span {
            font-size: 14px;
            letter-spacing: 0.06em;
            line-height: 24px;
            margin-top: 5px;
          }
        }
        .originalBox {
          .item {
            width: calc( (100% - 20px) / 2 );
            margin-bottom: 27px;
            &:nth-of-type(odd) {
              margin-right: 20px;
            }
            &:nth-of-type(4) {
              dd.title {
                padding: 18.5px 0;
              }
            }
            dt {
              padding-top: 90.90909090909091%;
            }
            dd {
              &.title {
                font-size: 18px;
                padding-bottom: 10px;
                margin: 13px 0 10px;
              }
              &.content {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }
      #ContBox03 {
        padding: 24px 0 83px;
        h3 {
          margin-bottom: 34px;
        }
        .subBox {
          h4 {
            background-position: right center;
            padding: 22px 30px 22px 44px;
            &:before { top: 20px; }
          }
          .showBox {
            padding: 0 0 21px 44px;
            margin-top: -8px;
            &:before {}
          }
        }
      }
    }
  }
}


/*
プラン詳細
-------------------------------------*/
#PagePlan.pageEntry {
  #Main {
    padding: 74px 0 192px;
    #ContBox01 {
      .contSubBox {
        max-width: 968px;
        background-color: #fff;
        padding: 60px;
        margin: 0 auto 57px;
        box-shadow: 0 3px 10px rgba(0,0,0,0.16);
        .title {
          font-size: 25px;
          line-height: 34px;
          text-align: justify;
          margin-bottom: 33px;
        }
        .subBox {
          display: flex;
          .content {
            p {
              line-height: 30px;
              &:last-of-type { margin-bottom: 0; }
              span { line-height: 1.5; }
            }
          }
        }
        .subBox01 {
          margin-bottom: 56px;
          .thumbnail {
            width: 61%;
            max-width: 520px;
            padding-top: 35.37735849056604%;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
          .content {
            flex: 1;
            margin-left: 34px;
          }
        }
        .subBox02 {
          padding-top: 28px;
          border-top: 1px solid $color_basic5;
          h4 {
            width: 170px;
            font-size: 16px;
            color: $color_basic2;
            letter-spacing: 0.1em;
            display: flex;
            align-items: center;
          }
          .content {
            flex: 1;
            margin-left: 67px;
          }
        }
      }
    }
  }
}

@media print,screen and (max-width: 767px) {
  #PagePlan.pageEntry {
    #Main {
      padding: 22px 0 78px;
      #ContBox01 {
        .contSubBox {
          max-width: 100%;
          width: 100%;
          padding: 30px 20px;
          margin-bottom: 40px;
          .title {
            font-size: 20px;
            line-height: 25px;
            margin-bottom: 26px;
          }
          .subBox {
            flex-direction: column;
          }
          .subBox01 {
            margin-bottom: 30px;
            .thumbnail {
              width: 100%;
              padding-top: 56.45161290322581%;
              margin-bottom: 23px;
            }
            .content {
              margin-left: 0;
              p { line-height: 25px; }
            }
          }
          .subBox02 {
            padding-top: 23px;
            h4 {
              width: 100%;
              margin-bottom: 15px;
            }
            .content {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

/*
アクセス・交通案内
-------------------------------------*/
#PageAccess.pageIndex {
  #Main{
    p{
      line-height: 30px;
      font-size: 16px;
    }
    #ContBox01{
      .contSubBox01{
        .subBox01{
            .leftBox{
                p{
                    letter-spacing: 0;
                    font-size: 25px;
                    span{
                        letter-spacing: .1em;
                        font-family: "Noto Sans JP", "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
                        font-size: 16px;
                    }
                    &::before{
                        content: "";
                        display: block;
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-image: url("../img/contents/logo.png");
                    }
                }
            }
            .rightBox{
                &> dl{
                    &>dt{
                        line-height: 1.2;
                        text-align: center;
                        font-size: 25px;
                        font-weight: 400;
                        a{
                            letter-spacing: 0;
                            font-size: 35px;
                            font-weight: 600;
                        }
                    }
                    &>dd{
                        dl{
                            display: table;
                            width: 100%;
                            &:last-of-type{
                                margin-bottom: 0;
                            }
                            dt, dd{
                                display: table-cell;
                                vertical-align: middle;
                            }
                            dt{
                                background-color: $color_basic1;
                                text-align: center;
                                font-size: 12px;
                                color: #fff;
                            }
                            dd{
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
        .subBox02{
            iframe{
                display: block;
                width: 100%;
            }
            .btnTypeArrow{
                background-color: transparent;
                span{
                    &::before{
                        content: "";
                        position: relative;
                        display: inline-block;
                        width: 19px;
                        height: 25px;
                        background-repeat: no-repeat;
                        background-position: left center;
                        background-image: url("../img/contents/access/icon_01.png");
                        background-size: 18.5px auto;
                    }
                }
            }
        }
      }
    }
    #ContBox02{
        .mainNav{
          .listTypeLower{
              li{
                  a{
                      display: block;
                      background-color: $color_basic1;
                      text-decoration: none;
                      font-size: 16px;
                      font-weight: 500;
                      color: #fff;
                      span{
                          position: relative;
                          display: block;
                          &::after, &:before{
                              content: "";
                              position: absolute;
                              top: 50%;
                              transform: translateY(-50%);
                              display: block;
                              background-repeat: no-repeat;
                              background-position: center center;
                          }
                          &::after{
                              width: 16px;
                              height: 6px;
                              background-image: url("../img/contents/access/arrow_01.png");
                              background-size: 16px auto;
                          }
                      }
                  }
                  &.list01{
                      a{
                          span{
                              &::before{
                                  width: 26px;
                                  height: 18px;
                                  background-image: url("../img/contents/access/icon_02.png");
                                  background-size: 26px auto;
                              }
                          }
                      }
                  }
                  &.list02{
                      a{
                          span{
                              &::before{
                                  width: 40px;
                                  height: 23px;
                                  background-image: url("../img/contents/access/icon_03.png");
                                  background-size: 40px auto;
                              }
                          }
                      }
                  }
                  &.list03{
                      a{
                          span{
                              &::before{
                                  width: 28px;
                                  height: 23px;
                                  background-image: url("../img/contents/access/icon_04.png");
                                  background-size: 27.6px auto;
                              }
                          }
                      }
                  }
              }
          }      
        }
        .contSubBox{
            &:last-of-type{
                margin-bottom: 0;
            }
            .titleTypeBorder{
                position: relative;
                border-bottom: 1px solid #707070;
                font-size: 20px;
                color: #8E827C;
                &::before{
                    content: "";
                    position: absolute;
                    transform: translateY(-50%);
                    background-repeat: no-repeat;
                    background-position: center center;
                }
            }
            .subBox{
                &.subBox02{
                    justify-content: space-between;
                    .item01{
                        dl{
                            align-items: center;
                            &:last-of-type{
                                margin-bottom: 0;
                            }
                            dt, dd{
                                text-align: center;
                                font-size: 16px;
                            }
                            dt{
                                background-color: $color_basic3;
                                line-height: 20px;
                                color: #fff;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                            dd{
                                position: relative;
                                &::before, &::after{
                                    content: "";
                                    display: block;
                                }
                                &::before{
                                    position: relative;
                                    background-repeat: no-repeat;
                                    background-position: center center;
                                }
                                &::after{
                                    position: absolute;
                                    left: 0;
                                    transform: translateY(-50%);
                                    border-top: 1px solid $color_basic3;
                                    border-bottom: 1px solid $color_basic3;
                                    width: 100%;
                                    height: 6px;
                                }
                            }
                        }
                    }
                    .item02{
                        background-repeat: no-repeat;
                        background-position: center center;
                        background-image: url("../img/contents/logo_w.png");
                        background-color: $color_basic2;
                        text-indent: -9999px;
                    }
                }
            }
            &.contSubBox01{
                .titleTypeBorder{
                    &::before{
                        width: 25px;
                        height: 18px;
                        background-image: url("../img/contents/access/icon_05.png");
                        background-size: 25px auto;
                    }
                }
                .subBox01{
                    .leftBox{
                        p{
                            margin-bottom: 0;
                        }
                    }
                    .rightBox{
                        img{
                            width: 100%;
                            height: auto;
                        }
                    }
                }
                .subBox02{
                    .item01{
                        dl{
                            dd{
                                &::before{
                                    width: 36px;
                                    height: 25px;
                                    background-image: url("../img/contents/access/icon_05.png");
                                    background-size: 36px auto;
                                }
                            }
                        }
                    }
                }
            }
            &.contSubBox02{
                .titleTypeBorder{
                    &::before{
                        width: 43px;
                        height: 23px;
                        background-image: url("../img/contents/access/icon_06.png");
                        background-size: 43px auto;
                    }
                }
                .subBox02{
                    .item01{
                        dl.dl01 dd:before{
                            width: 25px;
                            height: 40px;
                            background-image: url("../img/contents/access/icon_07.png");
                            background-size: 25px auto;
                        }
                        dl.dl02 dd:before, dl.dl03 dd:before, dl.dl04 dd:before{
                            width: 42px;
                            height: 25px;
                            background-image: url("../img/contents/access/icon_08.png");
                            background-size: 42px auto;
                        }
                    }
                }
            }
            &.contSubBox03{
                .titleTypeBorder{
                    &::before{
                        width: 28px;
                        height: 23px;
                        background-image: url("../img/contents/access/icon_09.png");
                        background-size: 28px auto;
                    }
                }
                .subBox02{
                    .item01{
                        dl{
                            align-items: stretch;
                            dt{
                            }
                            dd{
                              display: flex;
                              flex-wrap: wrap;
                              flex-direction: column;
                                &::before, &::after{
                                    display: none;
                                }
                              .box{
                                  position: relative;
                                  width: 100%;
                                &::before, &::after{
                                    content: "";
                                    display: block;
                                }
                                &::before{
                                    position: relative;
                                    background-repeat: no-repeat;
                                    background-position: center center;
                                }
                                &::after{
                                    position: absolute;
                                    left: 0;
                                    transform: translateY(-50%);
                                    border-top: 1px solid $color_basic3;
                                    border-bottom: 1px solid $color_basic3;
                                    width: 100%;
                                    height: 6px;
                                }
                              }
                            }
                        }
                        dl.dl01 .box01:before, dl.dl02 .box01:before{
                            width: 42px;
                            height: 25px;
                            background-image: url("../img/contents/access/icon_08.png");
                            background-size: 42px auto;
                        }
                        dl.dl01 .box02:before, dl.dl02 .box02:before{
                            width: 42px;
                            height: 37px;
                            background-image: url("../img/contents/access/icon_10.png");
                            background-size: 42px auto;
                        }
                    }
                }
            }
        }
    }
  }
}
@media print,screen and (min-width: 768px) {
  #PageAccess.pageIndex {
    #MainImg{
      background-image: url(../img/contents/access/mainimg_01.jpg);
    }
    #Main{
      #ContBox01{
        .innerBasic{
          padding-top: 116px;
          padding-bottom: 75px;
        }
        .contSubBox01{
          max-width: 1000px;
          margin: 0 auto;
          .subBox01{
              justify-content: space-between;
              margin-bottom: 57px;
              .leftBox{
                  padding-left: 3.1%;
                  width: 40%;
                  p{
                      margin-bottom: 0;
                      text-align: left;
                      &::before{
                          margin-bottom: 27px;
                          margin-left: 42px;
                          width: 233px;
                          height: 110px;
                          background-size: 232.5px auto;
                      }
                  }
              }
              .rightBox{
                  margin-top: 25px;
                  padding-right: 7.9%;
                  width: 41.5%;
                  &> dl{
                      &>dd{
                          padding: 12px 7px 0;
                          dl{
                              margin-bottom: 10px;
                              dt{
                                  padding: 3px 5px;
                                  width: 77px;
                              }
                              dd{
                                  padding-left: 10px;
                              }
                          }
                      }
                  }
              }
          }
          .subBox02{
              iframe{
                  height: 600px;
              }
              .btnTypeArrow{
                  margin-top: 44px;
                  padding: 5px 0 12px;
                  width: 272px;
                  span{
                      padding-left: 23px;
                      background-position: right top 16px;
                      &::before{
                          top: 7px;
                          left: -10px;
                      }
                  }
              }
          }
        }
      }
      #ContBox02{
        .innerBasic{
          padding-top: 64px;
          padding-bottom: 130px;
        }
        .mainNav{
          margin: 0 auto 38px;
          max-width: 867px;
          .listTypeLower{
            display: flex;
            justify-content: center;
            margin-right: -10px;
            margin-bottom: 36px;
            li{
              margin-right: 10px;
              a{
                padding: 16px 19px;
                transition: opacity .5s ease;
                span{
                    padding-right: 25px;
                    &::after{
                        top: 62%;
                        right: -3px;
                    }
                }
                &:hover{
                    opacity: .5;
                    transition: opacity .5s ease;
                }
              }
              &.list01{
                  a{
                      span{
                          padding-left: 47px;
                          &::before{
                              top: 50%;
                              left: 11px;
                          }
                      }
                  }
              }
              &.list02{
                  a{
                      span{
                          padding-left: 61px;
                          &::before{
                              top: 50%;
                              left: 7px;
                          }
                      }
                  }
              }
              &.list03{
                  a{
                      span{
                          padding-left: 43px;
                          &::before{
                              top: 50%;
                              left: 7px;
                          }
                      }
                  }
              }
            }
          }
        }
        .contSubBox{
          //max-width: 943px;
          max-width: 968px;
          margin: 0 auto 87px;
            &:last-of-type{
                margin-bottom: 0;
            }
            .titleTypeBorder{
                margin-bottom: 23px;
                padding-bottom: 14px;
            }
            .subBox{
                //padding-right: 30px;
                //padding-left: 30px;
                padding-right: 22px;
                padding-left: 22px;
                &.subBox01{
                    margin-bottom: 58px;
                }
                &.subBox02{
                    justify-content: space-between;
                    .item01{
                        width: 73%;
                        dl{
                            margin-bottom: 52px;
                            dt{
                                padding: 27px 5px;
                                width: 23.1%;
                            }
                            dd{
                                width: 76.9%;
                                &::after{
                                    top: 45%;
                                }
                            }
                        }
                    }
                    .item02{
                        width: 27%;
                        background-size: 169px auto;
                    }
                }
            }
            &.contSubBox01{
                .titleTypeBorder{
                    padding-left: 43px;
                    &::before{
                        top: 40%;
                        left: 5px;
                    }
                }
                .subBox01{
                    justify-content: space-between;
                    .leftBox{
                        width: 55%;
                    }
                    .rightBox{
                        margin-top: 13px;
                        width: 38%;
                    }
                }
                .subBox02{
                    .item01{
                        dl{
                            dd{
                                &::before{
                                    top: -7px;
                                    margin: 0 auto 16px;
                                }
                            }
                        }
                    }
                    .item02{
                        width: 27%;
                        background-size: 169px auto;
                    }
                }
            }
            &.contSubBox02{
                .titleTypeBorder{
                    padding-left: 61px;
                    &::before{
                        top: 40%;
                        left: 7px;
                    }
                }
                .subBox01{
                    margin-bottom: 38px;
                }
                .subBox02{
                    .item01{
                        dl.dl01 dd:before{
                            top: -15px;
                            left: 5px;
                            margin: 0 auto 16px;
                        }
                        dl.dl02 dd:before, dl.dl03 dd:before, dl.dl04 dd:before{
                            top: -15px;
                            left: 5px;
                            margin: 0 auto 16px;
                        }
                    }
                }
            }
            &.contSubBox03{
                .titleTypeBorder{
                    padding-left: 64px;
                    &::before{
                        top: 40%;
                        left: 12px;
                    }
                }
                .subBox01{
                    margin-bottom: 30px;
                }
                .subBox02{
                    .item01{
                        dl{
                            margin-bottom: 45px;
                            dd{
                              .box{
                                  &.box01{
                                      margin-top: 7px;
                                      margin-bottom: 80px;
                                  }
                                &::after{
                                    top: 45%;
                                }
                              }
                            }
                        }
                        dl.dl01 .box01:before, dl.dl02 .box01:before{
                            top: -8px;
                            margin: 0 auto 16px;
                        }
                        dl.dl01 .box02:before, dl.dl02 .box02:before{
                            top: -15px;
                            margin: 0 auto 10px;
                            width: 42px;
                            height: 37px;
                        }
                    }
                }
            }
        }
    }
    }
  }
}
@media print,screen and (max-width: 767px) {
  #PageAccess.pageIndex {
      #MainImg{
          background-image: url(../img/contents/access/mainimg_sp_01.jpg);
      }
      #Main{
          #ContBox01{
              .innerBasic{
                  padding-top: 35px;
                  padding-bottom: 59px;
              }
              .contSubBox01{
                  .subBox01{
                      display: block;
                      margin-bottom: 33px;
                      .leftBox{
                          p{
                              margin-bottom: 0;
                              text-align: center;
                              &::before{
                                  margin: 0 auto 25px;
                                  padding-top: 32%;
                                  width: 66%;
                                  height: 0;
                                  background-size: cover;
                              }
                          }
                      }
                      .rightBox{
                          margin-top: 23px;
                          &> dl{
                              &>dd{
                                  padding: 12px 15px 0;
                                  dl{
                                      margin-bottom: 10px;
                                      dt{
                                          padding: 3px 5px;
                                          width: 77px;
                                      }
                                      dd{
                                          padding-left: 10px;
                                      }
                                  }
                              }
                          }
                      }
                  }
                  .subBox02{
                      margin-right: -20px;
                      margin-left: -20px;
                      iframe{
                          height: 233px;
                      }
                      .btnTypeArrow{
                          margin-top: 31px;
                          padding: 5px 0 12px;
                          width: 272px;
                          span{
                              padding-left: 23px;
                              background-position: right top 16px;
                              &::before{
                                  top: 7px;
                                  left: -10px;
                              }
                          }
                      }
                  }
              }
          }
          #ContBox02{
              .innerBasic{
                  padding-bottom: 135px;
              }
              .mainNav{
                  margin-bottom: 50px;
                .listTypeLower{
                    margin-bottom: 25px;
                    li{
                        margin-bottom: 10px;
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                        a{
                            padding: 16px 19px;
                            span{
                                padding-right: 25px;
                                &::after{
                                    top: 62%;
                                    right: 2px;
                                }
                            }
                        }
                        &.list01{
                            a{
                                span{
                                    padding-left: 43px;
                                    &::before{
                                        top: 50%;
                                        left: 9px;
                                    }
                                }
                            }
                        }
                        &.list02{
                            a{
                                span{
                                    padding-left: 57px;
                                    &::before{
                                        top: 50%;
                                        left: 7px;
                                    }
                                }
                            }
                        }
                        &.list03{
                            a{
                                span{
                                    padding-left: 43px;
                                    &::before{
                                        top: 50%;
                                        left: 7px;
                                    }
                                }
                            }
                        }
                    }
                }
                  p{
                      line-height: 25px;
                  }
              }
              .contSubBox{
                  margin-bottom: 49px;
                  &:last-of-type{
                      margin-bottom: 0;
                  }
                  .titleTypeBorder{
                      margin-bottom: 21px;
                      padding-bottom: 11px;
                      &::before{
                          content: "";
                          position: absolute;
                          transform: translateY(-50%);
                          background-repeat: no-repeat;
                          background-position: center center;
                      }
                  }
                  .subBox{
                      &.subBox01{
                          margin-bottom: 31px;
                      }
                      &.subBox02{
                          justify-content: space-between;
                          .item01{
                              width: 66%;
                              dl{
                                  margin-bottom: 52px;
                                  dt{
                                      padding: 18px 1px;
                                      width: 53%;
                                      min-height: 94px;
                                  }
                                  dd{
                                      width: 47%;
                                      line-height: 15px;
                                      &::after{
                                          top: 45%;
                                      }
                                  }
                              }
                          }
                          .item02{
                              width: 34%;
                              background-size: 92px auto;
                          }
                      }
                  }
                  &.contSubBox01{
                      .titleTypeBorder{
                          padding-left: 40px;
                          &::before{
                              top: 40%;
                              left: 0;
                          }
                      }
                      .subBox01{
                          .leftBox{
                          }
                          .rightBox{
                              margin-top: 20px;
                          }
                      }
                      .subBox02{
                          .item01{
                              dl{
                                  dd{
                                      &::before{
                                          top: -16px;
                                          margin: 0 auto 6px;
                                      }
                                  }
                              }
                          }
                      }
                  }
                  &.contSubBox02{
                      .titleTypeBorder{
                          padding-left: 61px;
                          &::before{
                              top: 40%;
                              left: 7px;
                          }
                      }
                      .subBox01{
                          margin-bottom: 34px;
                      }
                      .subBox02{
                          .item01{
                              dl{
                                  margin-bottom: 28px;
                                  dd{
                                      &::after{
                                          top: 48%;
                                      }
                                  }
                              }
                              .dl02, .dl03, .dl04{
                                  dd{
                                      font-size: 14px;
                                      &::after{
                                          top: 53%;
                                      }
                                  }
                              }
                              .dl03, .dl04{
                                  dd{
                                      &::after{
                                          top: 53%;
                                      }
                                      &::before{
                                          top: -1px;
                                          margin: 0 auto 10px;
                                      }
                                  }
                              }
                              dl.dl01 dd:before{
                                  top: -20px;
                                  left: 5px;
                                  margin: 0 auto -2px;
                              }
                              dl.dl02 dd:before, dl.dl03 dd:before, dl.dl04 dd:before{
                                  top: 6px;
                                  left: 5px;
                                  margin: 0 auto 22px;
                              }
                          }
                      }
                  }
                  &.contSubBox03{
                      .titleTypeBorder{
                          padding-left: 45px;
                          &::before{
                              top: 40%;
                              left: 12px;
                          }
                      }
                      .subBox01{
                          margin-bottom: 30px;
                      }
                      .subBox02{
                          .item01{
                              dl{
                                  margin-bottom: 45px;
                                  dt{
                                      line-height: 20px;
                                      font-size: 14px;
                                  }
                                  dd{
                                    margin-top: 10px;
                                    .box{
                                        line-height: 15px;
                                        font-size: 14px;
                                        &.box01{
                                            margin-top: 7px;
                                            margin-bottom: 57px;
                                        }
                                      &::after{
                                          top: 45%;
                                      }
                                    }
                                  }
                              }
                              dl.dl01 .box01:before, dl.dl02 .box01:before{
                                  top: -8px;
                                  margin: 0 auto 16px;
                              }
                              dl.dl01 .box02:before, dl.dl02 .box02:before{
                                  top: -15px;
                                  margin: 0 auto 10px;
                                  width: 32px;
                                  height: 29px;
                                  background-size: 32px auto;
                              }
                          }
                      }
                  }
              }
          }
       }
  }
}

/*
プライバシーポリシー
-------------------------------------*/
#PagePrivacy.pageIndex {
  #Main{
      p{
          margin-bottom: 29px;
          line-height: 30px;
      }
      #ContBox01{
          .contSubBox{
              &:last-of-type{
                  margin-bottom: 0;
              }
              p{
                  &:last-child{
                      margin-bottom: 0;
                  }
              }
              &.contSubBox06{
                  dl{
                      border: 1px solid #EAEAEA;
                      background-color: #fff;
                      line-height: 30px;
                      text-align: center;
                      font-size: 16px;
                      font-weight: 500;
                  }
              }
          }
      }
  }
}
@media print,screen and (min-width: 768px) {
  #PagePrivacy.pageIndex {
      #MainImg{
          background-image: url(../img/contents/privacy/mainimg_01.jpg);
      }
      #Main{
          #ContBox01{
              .innerBasic{
                  padding-top: 114px;
                  padding-bottom: 75px;
              }
              .contSubBox{
                  max-width: 968px;
                  margin: 0 auto 31px;
                  &.contSubBox06{
                      dl{
                          margin: 44px auto 0;
                          padding: 17px 5px;
                          max-width: 245px;
                      }
                  }
              }
          }
      }
  }
}

@media print,screen and (max-width: 767px) {
  #PagePrivacy.pageIndex {
      #MainImg{
          background-image: url(../img/contents/privacy/mainimg_sp_01.jpg);
      }
      #Main{
          #ContBox01{
              .innerBasic{
                  padding-top: 52px;
                  padding-bottom: 101px;
              }
              .contSubBox{
                  margin-bottom: 31px;
                  &.contSubBox06{
                      dl{
                          margin: 55px auto 0;
                          padding: 17px 5px;
                      }
                  }
              }
          }
      }
  }
}


#Page404.pageIndex {
  #Main {
    .contBox {
      padding: 50px 0 100px;
      .titleTypeEnBg {
        padding-top: 0;
        &:before { display: none; }
      }
      p { text-align: center; }
    }
  }
}